import { useState, useEffect, useRef } from 'react'
import {
  Container,
  Menu,
  Image,
} from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import Menubar from './components/Menubar'

function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

const fakeApiToPreserveIFrame = async () => {
  await delay(50)
  return {
    src: 'https://oreilly-qc.github.io/#',
  }
}

const Library = ({ consoleMode }) => {
  console.warn('consoleMode:', consoleMode, '(deprecate)')
  const navigate = useNavigate()

  const [iframeUrl, setIframeUrl] = useState(null)
  const loadIframe = async () => {
    const { src } = await fakeApiToPreserveIFrame({ consoleMode })
    setIframeUrl(src)
  }
  useEffect(() => {
    loadIframe()
  }, [])

  // TODO: Consider creating Quantum Copilot kernel based on:
  // https://github.com/jupyterlite/echo-kernel

  // TODO:
  // Communication between a host page and a JupyterLite instance running in an IFrame
  // https://jupyterlite.readthedocs.io/en/latest/howto/configure/advanced/iframe.html
  //
  // Explore posibility to control Jupyter Light with something like this:
  // iframe.contentWindow.jupyterapp.commands.execute(commandId, {arg: value})
  //
  // https://discourse.jupyter.org/t/external-api-calls-to-trigger-jupyterlab-features/12425/4
  //
  // Terraforming Jupyter: Changing JupyterLab to suit your needs
  // https://youtu.be/a9P7qv4P5LE?si=rvBJ2GooUOTgW_68
  const iframeRef = useRef(null);
  // const handleClick = () => {
  //   console.log('iframeRef:', iframeRef)
  //   console.log('window:', window)
  //   console.log('window.frames:', window.frames)
  //   console.log('window.frames.jupyterlab:', window.frames.jupyterlab)
  //   // textInput.current.focus();
  // }

  return (
    <div>
      <Container>
        <Menubar />
      </Container>

      {/*
      <input
        type="button"
        value="Focus the text input"
        onClick={handleClick}
      />
      */}

      <Container fluid style={{marginTop: '0.3em'}}>
        <iframe
          title="JupyterLite IFrame"
          src={iframeUrl}
          width="100%"
          height="1248px"
          ref={iframeRef}
        >
        </iframe>

      </Container>
    </div>
  )
}
export default Library

