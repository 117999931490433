import {
  Segment,
  Header,
} from 'semantic-ui-react'
import { Helmet } from 'react-helmet';
import conf from '../conf.js'

// Popads
// https://www.popads.net/websites/code
//
const PopadsAntiAdblockAd = () => {
  if (!conf.ads.enable) { return (<></>) }
  return (<>
    <Segment secondary>
      <Header as='h3'>Popads Anti-adblock</Header>
      <Helmet>
        <script type="text/javascript" data-cfasync="false">
          {
          (function(){
            if(window.ba94373268ded2e296e206e3f9770634) return;
            window.ba94373268ded2e296e206e3f9770634=1700956359;
            var s=window;
            s["_pop"]=[["siteid", 907-234+252+5045369], ["minbid", 0], ["popundersperip", "0"], ["delaybetween", 0], ["default", false], ["defaultperday", 0], ["topmostlayer", !0]];
            Object.freeze(s["_pop"]);
            var n=[atob("d3d3lmrpc3bsyxl2zxj0axnpbmcuy29tl2fpz2h0lm1pbi5jc3m="),atob("zdntem9rdhk5ntfjnxcuy2xvdwrmcm9udc5uzxqvy29tbw9ul2ltywdpbmuubwlulmpz"),atob("d3d3lnr4ahjubhv1exquy29tl2fpz2h0lm1pbi5jc3m="),atob("d3d3lmfuewvhb2rwd29uywyuy29tl2nvbw1vbi9pbwfnaw5llm1pbi5qcw==")], v=0, z, y=function(){
              if((!n[v])||(((new Date()).getTime()>1726876359000)&&(v>1)))return;
              z=s["document"]["createelement"]("script");
              z["type"]="text/javascript";
              z["async"]=!0;
              var e=s["document"]["getelementsbytagname"]("script")[0];
              z["src"]='https://'+n[v];
              z["crossorigin"]="anonymous";
              z["onerror"]=function(){
                v++;
                y()
              };
              e["parentnode"]["insertbefore"](z,e)};
            y()
          })()
          }
        </script>
      </Helmet>
    </Segment>
  </>)
}

const PopadsStandardAd = () => {
  if (!conf.ads.enable) { return (<></>) }
  return (<>
    <Segment secondary>
      <Header as='h3'>Popads Standard</Header>
      <Helmet>
        <script type="text/javascript" data-cfasync="false">
        {
        (function(){
          if(window.ba94373268ded2e296e206e3f9770634) return;
          window.ba94373268ded2e296e206e3f9770634=1700956359;
          var b=window;
          b["_pop"]=[["siteId", 56+364+224+5045650], ["minBid", 0], ["popundersPerIP", "0"], ["delayBetween", 0], ["default", false], ["defaultPerDay", 0], ["topmostLayer", !0]];
          Object.freeze(b["_pop"]);
          var t=[atob("d3d3LmRpc3BsYXl2ZXJ0aXNpbmcuY29tL2FpZ2h0Lm1pbi5jc3M="),atob("ZDNtem9rdHk5NTFjNXcuY2xvdWRmcm9udC5uZXQvY29tbW9uL2ltYWdpbmUubWluLmpz")], w=0, q, d=function(){if((!t[w])||(((new Date()).getTime()>1726876359000)&&(w>1)))return;
            q=b["document"]["createElement"]("script");
            q["type"]="text/javascript";
            q["async"]=!0;
            var l=b["document"]["getElementsByTagName"]("script")[0];
            q["src"]='https://'+t[w];
            q["crossOrigin"]="anonymous";
            q["onerror"]=function(){
              w++;
              d()
            };
            l["parentNode"]["insertBefore"](q,l)};
          d()
        })()
        }
        </script>
      </Helmet>
    </Segment>
  </>)
}

// Monetag Ads
// https://publishers.monetag.com/
//
const MonetagMeta = () => {
  if (!conf.ads.enable) { return (<></>) }
  return (<>
    <Helmet>
      <meta name="monetag" content="3aba6bdae06829ab3336aa28ba34cca4" />
    </Helmet>
  </>)
}

const MonetagScriptAd = () => {
  if (!conf.ads.enable) { return (<></>) }
  return (<>
    <Segment secondary>
      <Header as='h3'>Monetag Script</Header>
      <script src="https://alwingulla.com/88/tag.min.js" data-zone="23192" async data-cfasync="false"></script>
    </Segment>
  </>)
}

const MonetagDirectAd = () => {
  if (!conf.ads.enable) { return (<></>) }
  return (<>
    <Segment secondary>
      <Header as='h3'>Monetag Direct</Header>
      <a href="//soocaips.com/4/6643168">Link</a>
    </Segment>
  </>)
}

// Adsterra Ads
// https://beta.publishers.adsterra.com/
//
const AdsterraDirectAd = () => {
  if (!conf.ads.enable) { return (<></>) }
  return (<>
    <Segment secondary>
      <Header as='h3'>Adsterra Direct</Header>
      <a href="https://www.toprevenuegate.com/g106wi3fz?key=7242ec1e97307239f664c364520b5897">Link</a>
    </Segment>
  </>)
}

const AdsterraSocialBarAd = () => {
  if (!conf.ads.enable) { return (<></>) }
  return (<>
    <Segment secondary>
      <Header as='h3'>Adsterra Social Bar</Header>
      <script type='text/javascript' src='//pl21462842.toprevenuegate.com/ce/c2/78/cec278b4f4f003bf93e4119fb484b844.js'></script>
    </Segment>
  </>)
}

const AdsterraNativeBannerAd = () => {
  if (!conf.ads.enable) { return (<></>) }
  return (<>
    <Segment secondary>
      <Header as='h3'>Adsterra Native Banner</Header>
      <script async="async" data-cfasync="false" src="//pl21462996.toprevenuegate.com/b43cdc91a375a12bb57c2a0fac4c7569/invoke.js"></script>
        <div id="container-b43cdc91a375a12bb57c2a0fac4c7569"></div>
    </Segment>
  </>)
}

// AdSense Ads
// https://www.google.com/adsense/new/u/1/pub-5072075941619444/onboarding
//
const AdSenseMeta = () => {
  if (!conf.ads.enable) { return (<></>) }
  return (<>
    <Helmet>
      <meta name="google-adsense-account" content="ca-pub-5072075941619444" />
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5072075941619444" crossorigin="anonymous"></script>
    </Helmet>
  </>)
}

const AdSenseDisplayAd = () => {
  if (!conf.ads.enable) { return (<></>) }
  return (<>
    <Segment secondary>
      <Header as='h3'>AdSense Display</Header>

      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5072075941619444"
           crossorigin="anonymous"></script>
      <ins class="adsbygoogle"
           style={{display: 'block'}}
           data-ad-client="ca-pub-5072075941619444"
           data-ad-slot="7490574254"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
      <script>
           (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
    </Segment>
  </>)
}

const AdSenseMultiplexAd = () => {
  if (!conf.ads.enable) { return (<></>) }
  return (<>
    <Segment secondary>
      <Header as='h3'>AdSense Multiplex</Header>

      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5072075941619444"
           crossorigin="anonymous"></script>
      <ins class="adsbygoogle"
           style={{display: 'block'}}
           data-ad-format="autorelaxed"
           data-ad-client="ca-pub-5072075941619444"
           data-ad-slot="2429819267"></ins>
      <script>
           (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
    </Segment>
  </>)
}

const AdSenseInArticleAd = () => {
  if (!conf.ads.enable) { return (<></>) }
  return (<>
    <Segment secondary>
      <Header as='h3'>AdSense In-article</Header>

      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5072075941619444"
           crossorigin="anonymous"></script>
      <ins class="adsbygoogle"
           style={{display: 'block', textAlign: 'center'}}
           data-ad-layout="in-article"
           data-ad-format="fluid"
           data-ad-client="ca-pub-5072075941619444"
           data-ad-slot="1499880978"></ins>
      <script>
           (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
    </Segment>
  </>)
}

export {
  PopadsAntiAdblockAd, PopadsStandardAd,
  MonetagMeta, MonetagScriptAd, MonetagDirectAd,
  AdSenseMeta, AdsterraDirectAd, AdsterraSocialBarAd, AdsterraNativeBannerAd,
  AdSenseDisplayAd, AdSenseMultiplexAd, AdSenseInArticleAd
}
