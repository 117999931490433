import { useState, useEffect, useRef } from 'react'
import { isEmpty } from 'lodash'
import axios from 'axios'
import {
  Container,
  Header,
  Segment,
  Form,
  Loader,
  Message,
  Image,
} from 'semantic-ui-react'
import QuantumCircuit from 'quantum-circuit'
import { TextAuto, QCMarkdown } from './components/Text'
import Menubar from './components/Menubar'
import conf from './conf'

const Run = () => {
  const [ circuit, setCircuit ] = useState(new QuantumCircuit())
  const [ svg, setSvg ] = useState(null)
  const [ code, setCode ] = useState('')
  const [ format, setFormat ] = useState('qasm')
  const [ codeError, setCodeError ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ convert, setConvert ] = useState('none')
  const [ asJupyter, setAsJupyter ] = useState(false)
  const [ visualize, setVisualize ] = useState(false)
  const [ transcode, setTranscode ] = useState('')
  const [ device, setDevice ] = useState('none')
  const [ output, setOutput ] = useState('')
  const [ drawing, setDrawing ] = useState('')
  const [ responseError, setResponseError ] = useState('')

  const formatOptions = [
    { key: 'qasm', value: 'qasm', text: 'OpenQASM' },
    { key: 'quil', value: 'quil', text: 'Quil' },
    { key: 'qobj', value: 'qobj', text: 'Qiskit Qobj' },
    { key: 'ionq', value: 'ionq', text: 'IonQ JSON' },
    { key: 'native', value: 'native', text: 'Quantum Circuit' },
  ]

  const convertOptions = [
    { key: 'none', value: 'none', text: '(None)' },
    { key: 'qasm', value: 'qasm', text: 'OpenQASM' },
    { key: 'js', value: 'js', text: 'JavaScript' },
    { key: 'qiskit', value: 'qiskit', text: 'Qiskit (Python)' },
    { key: 'pyquil', value: 'pyquil', text: 'pyQuil (Python)' },
    { key: 'quil', value: 'quil', text: 'Quil' },
    { key: 'cirq', value: 'cirq', text: 'Cirq (Python)' },
    { key: 'quest', value: 'quest', text: 'QuEST (C/C++)' },
    { key: 'qsharp', value: 'qsharp', text: 'Q#' },
    { key: 'qobj', value: 'qobj', text: 'Qiskit Qobj' },
    { key: 'tfq', value: 'tfq', text: 'Tensorflow Quantum (Python)' },
    { key: 'braket', value: 'braket', text: 'Braket (Python)' },
    { key: 'pyaqasm', value: 'pyaqasm', text: 'pyAQASM (Python)' },
    { key: 'aqasm', value: 'aqasm', text: 'AQASM (Python)' },
    { key: 'quirk', value: 'quirk', text: 'Quirk' },

    { key: 'native', value: 'native', text: 'Quantum Circuit' },

    { key: 'svg', value: 'svg', text: 'SVG' }, // different output
    // { key: 'ionq', value: 'ionq', text: 'IonQ JSON' }, // not implemented
  ]

  const formatMapsLanguage = {
    'none': '',
    'qasm': 'openqasm',
    'js': 'js',
    'qiskit': 'python',
    'pyquil': 'python',
    'quil': 'quil',
    'cirq': 'python',
    'quest': 'cpp',
    'qsharp': 'qsharp',
    'qobj': 'js',
    'tfq': 'python',
    'braket': 'python',
    'pyaqasm': 'python',
    'aqasm': 'python',
    'quirk': 'js',
    'svg': 'html',
    'ionq': 'js',
    'native': 'js',
  }

  const deviceOptions = [
    { key: 'none', value: 'none', text: '(None)' },

    // Web
    { key: 'quirk', value: 'quirk', text: 'Quirk Web Simulator' },
    { key: 'quantum-cirquit', value: 'quantum-cirquit', text: 'Quantum-Cirquit Web Simulator' },
    { key: 'transpiler', value: 'transpiler', text: 'Transpiler' },

    // Native Primitives
    // { key: 'ibm_perth', value: 'ibm_perth', text: 'IBM Qiskit Runtime (Perth)' },
    // { key: 'aer_native', value: 'aer_native', text: 'Aer Native Primitive' },

    // Quantum Hardware
    { key: 'ibm_torino', value: 'ibm_torino', text: 'IBM Quantum (Torino) 🔒' },
    { key: 'ibm_sherbrooke', value: 'ibm_sherbrooke', text: 'IBM Quantum (Sherbrooke)' },
    { key: 'ibm_brisbane', value: 'ibm_brisbane', text: 'IBM Quantum (Brisbane)' },
    { key: 'ibm_kyiv', value: 'ibm_kyiv', text: 'IBM Quantum (Kyiv) 🔒' },
    { key: 'ibm_rensselaer', value: 'ibm_rensselaer', text: 'IBM Quantum (Rensselaer) 🔒' },
    { key: 'ibm_quebec', value: 'ibm_quebec', text: 'IBM Quantum (Quebec) 🔒' },
    { key: 'ibm_kawasaki', value: 'ibm_kawasaki', text: 'IBM Quantum (Kawasaki) 🔒' },
    { key: 'ibm_osaka', value: 'ibm_osaka', text: 'IBM Quantum (Osaka)' },
    { key: 'ibm_cleveland', value: 'ibm_cleveland', text: 'IBM Quantum (Cleveland) 🔒' },
    { key: 'ibm_nazca', value: 'ibm_nazca', text: 'IBM Quantum (Nazca) 🔒' },
    { key: 'ibm_kyoto', value: 'ibm_kyoto', text: 'IBM Quantum (Kyoto)' },
    { key: 'ibm_cusco', value: 'ibm_cusco', text: 'IBM Quantum (Cusco) 🔒' },

    // { key: 'ibm_nairobi', value: 'ibm_nairobi', text: 'IBM Quantum (Nairobi)' },
    // { key: 'ibm_lagos', value: 'ibm_lagos', text: 'IBM Quantum (Lagos)' },
    // { key: 'ibmq_jakarta', value: 'ibmq_jakarta', text: 'IBM Quantum (Jakarta)' },
    // { key: 'ibmq_manila', value: 'ibmq_manila', text: 'IBM Quantum (Manila)' },
    // { key: 'ibmq_quito', value: 'ibmq_quito', text: 'IBM Quantum (Quito)' },
    // { key: 'ibmq_belem', value: 'ibmq_belem', text: 'IBM Quantum (Belem)' },
    // { key: 'ibmq_lima', value: 'ibmq_lima', text: 'IBM Quantum (Lima)' },

    // { key: 'aqt_innsbruck', value: 'aqt_innsbruck', text: 'AQT Innsbruck' },
    // { key: 'ionq_qpu', value: 'ionq_qpu', text: 'IonQ (QPU)' },
    // { key: 'iqm_server_url', value: 'iqm_server_url', text: 'IQM' }, // it needs IQM server URL
    // { key: 'quantinuum_deadhead', value: 'quantinuum_deadhead', text: 'Quantinuum (Deadhead)' }, // it needs Quantinuum user account
    // { key: 'rigetti_aspen_11', value: 'rigetti_aspen_11', text: 'Rigetti (Aspen-11)' },

    // Local Simulators
    // { key: 'aer_local', value: 'aer_local', text: 'Aer Local Simulator' },
    // { key: 'qasm_simulator', value: 'qasm_simulator', text: 'QASM Simulator' },
    // { key: 'nvidia_custatevec', value: 'nvidia_custatevec', text: 'NVIDIA cuStateVec' },
    // { key: 'quac', value: 'quac', text: 'QuaC' },

    // Cloud Simulators
    { key: 'simulator_statevector', value: 'simulator_statevector', text: 'IBMQ Cloud Statevector Simulator' },
    { key: 'ibmq_qasm_simulator', value: 'ibmq_qasm_simulator', text: 'IBMQ QASM Simulator' },
    { key: 'simulator_mps', value: 'simulator_mps', text: 'IBMQ MPS Simulator' },
    // { key: 'ionq_simulator', value: 'ionq_simulator', text: 'IonQ Quantum Cloud Simulator' }, // needs IonQ token
    // { key: 'rigetti_qvm', value: 'rigetti_qvm', text: 'Rigetti Quantum Virtual Machine' },

    // Multi-platforms
    // { key: 'amazon_braket_aquila', value: 'amazon_braket_aquila', text: 'Amazon Braket (Aquila)' },
    // { key: 'azure_quantum_quantinuum', value: '', text: 'Azure Quantum (Quantinuum)' }, // needs resource id and location
    // { key: 'gaqqie', value: 'gaqqie', text: 'Gaqqie' }, // needs url
    // { key: 'qcware_forge_statevector', value: 'qcware_forge_statevector', text: 'QC Ware Forge' },
    // { key: 'strangeworks', value: 'strangeworks', text: 'Strangeworks' }, // needs api key
    // { key: 'superstaq', value: 'superstaq', text: 'SuperstaQ' },
  ]

  const handleSimulate = () => {
    setLoading(true)

    try {
      format === 'qasm' && circuit.importQASM(code, (errors) => {
        console.error('importQASM errors:', errors)
      })
      format === 'quil' && circuit.importQuil(code, (errors) => {
        console.error('importQuil errors:', errors)
      })
      format === 'qobj' && circuit.importQobj(JSON.parse(code), (errors) => {
        console.error('importQobj errors:', errors)
      })
      format === 'ionq' && circuit.importIonq(JSON.parse(code), (errors) => {
        console.error('importIonq errors:', errors)
      })
      format === 'native' && circuit.load(JSON.parse(code))
      format === 'native' && console.log('native: parsed:', JSON.parse(code))
      circuit.run()
      setCircuit(circuit)
      setSvg(circuit.exportSVG(true))

      handleConvert()
    } catch (error) {
      console.error('submit error:', error);
    } finally {
      setLoading(false)
    }
  }

  const handleConvert = () => {
    try {
      const params = {
        cirquitName: 'Quantum Copilot Cirquit',
        comment: 'Generated in ⟨Quantum|Copilot⟩',
        decompose: true,
        asJupyter,
      }
      convert === 'none' && setTranscode('')
      convert === 'qasm' && setTranscode(circuit.exportToQASM(params, false))
      convert === 'js' && setTranscode(circuit.exportJavaScript(params.comment, params.decompose, null, params.asJupyter))
      convert === 'qiskit' && setTranscode(circuit.exportToQiskit(params, false, null, null))
      convert === 'pyquil' && setTranscode(circuit.exportToPyquil(params, false))
      convert === 'quil' && setTranscode(circuit.exportToQuil(params, false))
      convert === 'cirq' && setTranscode(circuit.exportToCirq(params, false))
      convert === 'quest' && setTranscode(circuit.exportToQuEST(params, false))
      convert === 'qsharp' && setTranscode(circuit.exportToQSharp(params, false))
      convert === 'qobj' && setTranscode(JSON.stringify(circuit.exportToQobj(params, false), null, 2))
      convert === 'tfq' && setTranscode(circuit.exportToTFQ(params, false))
      convert === 'braket' && setTranscode(circuit.exportToBraket(params, false))
      convert === 'pyaqasm' && setTranscode(circuit.exportToPyAQASM(params, false))
      convert === 'aqasm' && setTranscode(circuit.exportToAQASM(params, false))
      convert === 'quirk' && setTranscode(JSON.stringify(circuit.exportQuirk(true), null, 2))
      convert === 'svg' && setTranscode(circuit.exportSVG(false))
      convert === 'native' && setTranscode(JSON.stringify(circuit.save(), null, 2))
    } catch (error) {
      console.error('convert error:', error);
    }
  }

  useEffect(() => {
    handleConvert()
  }, [convert, asJupyter]);

  const handleRun = async () => {
    if (isEmpty(code)) {
      return setCodeError('Please enter a code')
    } else {
      setCodeError('')
    }
    setLoading(true)

    if (['quirk', 'quantum-cirquit', 'transpiler'].includes(device)) {
      return handleSimulate()
    }

    try {
      const response = await axios.post(`${conf.api.url}/run`, {
        code,
        device
      }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
        crossOrigin: { mode: 'cors' },
      })
      console.log('response:', response)
      setOutput(response?.data?.output)
      setDrawing(response?.data?.drawing)
      setResponseError(response?.data?.error)
    } catch (err) {
      console.error('handleRun error:', err)
    } finally {
      setLoading(false)
    }
  }

  const iframeRef = useRef(null)
  if (iframeRef) {
    iframeRef.current?.contentWindow.document
      .getElementById("close-menu-button")?.click()
  }

  const handleDownload = () => {
    const element = document.createElement("a")
    const file = new Blob([transcode], {type: 'text/plain'})
    element.href = URL.createObjectURL(file)
    element.download = "quantum-copilot-generated.ipynb"
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }


  // console.log('svg:', svg)
  // console.log('format:', format)
  return (
  <div>
    <Container>
      <Menubar />

      <Segment secondary>
        <Form>
          <Form.Group inline>
            <Form.Select
              label='Format:'
              placeholder='Select circuit format'
              options={formatOptions}
              onChange={(e, d) => setFormat(d.value) }
              value={format}
            />
            <Form.Select
              label='Quantum Device:'
              placeholder='Select quantum device'
              options={deviceOptions}
              onChange={(e, d) => setDevice(d.value) }
              value={device}
              disabled={format!=='qasm'}
            />
            <Form.Button
              icon='play'
              content='Run'
              disabled={format!=='qasm' || device==='none'}
              onClick={handleRun}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              control={TextAuto}
              placeholder="Write a code"
              onChange={e => setCode(e.target.value)}
              useCacheForDOMMeasurements
              value={code}
              error={ !isEmpty(codeError) && {
                content: codeError,
                pointing: 'above',
              }}
              required
              width={16}
            />
          </Form.Group>
        </Form>
      </Segment>
    </Container>

    <Loader active={loading} inline='centered' style={{ marginTop: '1em', marginBottom: '1em' }}/>

    { [ 'simulator_statevector',
        'ibmq_qasm_simulator',
        'simulator_mps',
        'ibm_perth',
        'ibm_nairobi',
        'ibm_lagos',
        'ibmq_jakarta',
        'ibmq_manila',
        'ibmq_quito',
        'ibmq_belem',
        'ibmq_lima',
      ].includes(device) && (
      <Container style={{marginTop: '0.3em'}}>
        <Segment style={{marginTop: '1em'}}>
          { responseError &&
            <Message
              negative
              style={{ textAlign: 'left'}}
              icon='exclamation circle'
              header='Runtime Error'
              content={responseError}
              onDismiss={() => setResponseError('')}
            />
          }

          <Header as='h5' attached='top' secondary>
            Runtime Output
          </Header>
          <Segment attached>
            <pre>
            {output}
            </pre>
          </Segment>
          <Header as='h5' attached='top' secondary>
            Cirquit Drawing
          </Header>
          <Segment attached>
            <pre>{drawing}</pre>
          </Segment>

        </Segment>
      </Container>
    )}

    { device === 'quirk' && (
      <Container fluid style={{marginTop: '0.3em'}}>
        <Segment>
          <Header as='h3' attached='top'>Circuit Simulator</Header>
          <iframe
            title="JupyterLite IFrame"
            src={'/quirk/quirk.html#circuit=' + JSON.stringify(circuit.exportQuirk(true)) }
            width="100%"
            height="850px"
            ref={iframeRef}
          >
          </iframe>
        </Segment>
      </Container>
    )}

    { device === 'quantum-cirquit' && (
      <Container style={{marginTop: '0.3em'}}>
        <Segment style={{marginTop: '1em'}}>
          <Header as='h3'>Measurement</Header>

          <Header as='h5' attached='top' secondary>
            Probabilities
          </Header>
          <Segment attached>
            <pre>
            {circuit && circuit.probabilities().join(', ')}
            </pre>
          </Segment>
          <Header as='h5' attached>
            Measurement
          </Header>
          <Segment attached>
            <pre>
            {circuit && circuit.measureAll()}
            </pre>
          </Segment>
          <Header as='h5' attached>
            State Vector (Possible Values Only)
          </Header>
          <Segment attached>
            <pre>
            {circuit && circuit.stateAsString(true)}
            </pre>
          </Segment>
        </Segment>
      </Container>
    )}

    { device === 'transpiler' && (
      <Container style={{marginTop: '0.3em'}}>
        <Segment style={{marginTop: '1em'}}>
          <Segment secondary>
            <Form>
              <Form.Group inline>
                <Form.Select
                  label='Convert code to:'
                  placeholder='Select convert format'
                  options={convertOptions}
                  onChange={ (e, data) => setConvert(data.value) }
                  value={convert}
                />
                <Form.Checkbox inline
                  label='Jupyter'
                  checked={asJupyter}
                  onChange={(e, data) => setAsJupyter(data.checked) }
                />
                <Form.Checkbox inline
                  label='Visualize'
                  checked={visualize}
                  onChange={(e, data) => setVisualize(data.checked) }
                />
                <Form.Button
                  icon='download'
                  content='Download'
                  disabled={!asJupyter}
                  onClick={handleDownload}
                />
              </Form.Group>
            </Form>
          </Segment>

          { convert !== 'none' && (
            <>
              <Header as='h5' attached='top' style={{marginBottom: '-14px'}}>
                Converted Cirquit Code
              </Header>
              <QCMarkdown dark>
                { '```' +
                  (asJupyter ? 'javascript' : formatMapsLanguage[convert]) +
                  '\n' + transcode + '\n```'
                }
              </QCMarkdown>
            </>
          )}

          { visualize && (
            <>
              <Header as='h5' attached='top' secondary>
                Circuit Image
              </Header>
              <Segment attached>
                <div dangerouslySetInnerHTML={{ __html: svg }} />
              </Segment>
            </>
          ) }

        </Segment>
      </Container>
    )}
  </div>
  )
}
export default Run

