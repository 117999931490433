import { useState, useEffect, useRef } from 'react'
import {
  Container,
} from 'semantic-ui-react'
import Menubar from './components/Menubar'
import conf from './conf.js'

function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

const fakeApiToPreserveIFrame = async () => {
  await delay(50)
  if (!conf.chat.console) {
    return {
      // Jupyter Lab Light
      src: '/jupyter/lab/index.html'
        // TODO:
        // The extension will open a file passed via a URL parameter.
        // https://github.com/jupyterlab-contrib/jupyterlab-open-url-parameter
        //
        + '?fromURL=https://raw.githubusercontent.com/jupyterlab/jupyterlab-demo/master/notebooks/Lorenz.ipynb'
        + '&fromURL=https://raw.githubusercontent.com/jupyterlab/jupyterlab-demo/master/data/iris.csv'
    }
  } else {
    return {
      // Jupyter REPL
      src: '/jupyter/repl/index.html?kernel=python&toolbar=1&code=print("abc")',
    }
  }
}

const Code = () => {
  const [iframeUrl, setIframeUrl] = useState(null)
  const loadIframe = async () => {
    const { src } = await fakeApiToPreserveIFrame()
    setIframeUrl(src)
  }
  useEffect(() => {
    loadIframe()
  }, [])

  // TODO: Consider creating Quantum Copilot kernel based on:
  // https://github.com/jupyterlite/echo-kernel

  // TODO:
  // Communication between a host page and a JupyterLite instance running in an IFrame
  // https://jupyterlite.readthedocs.io/en/latest/howto/configure/advanced/iframe.html
  //
  // Explore posibility to control Jupyter Light with something like this:
  // iframe.contentWindow.jupyterapp.commands.execute(commandId, {arg: value})
  //
  // https://discourse.jupyter.org/t/external-api-calls-to-trigger-jupyterlab-features/12425/4
  //
  // Terraforming Jupyter: Changing JupyterLab to suit your needs
  // https://youtu.be/a9P7qv4P5LE?si=rvBJ2GooUOTgW_68
  const iframeRef = useRef(null);
  // const handleClick = () => {
  //   console.log('iframeRef:', iframeRef)
  //   console.log('window:', window)
  //   console.log('window.frames:', window.frames)
  //   console.log('window.frames.jupyterlab:', window.frames.jupyterlab)
  //   // textInput.current.focus();
  // }

  return (
    <div>
      <Container>
        <Menubar />
      </Container>

      <Container fluid style={{marginTop: '0.3em'}}>
        <iframe
          title="JupyterLite IFrame"
          src={iframeUrl}
          width="100%"
          height="850px"
          ref={iframeRef}
        >
        </iframe>

      </Container>
    </div>
  )
}
export default Code

