import React from 'react'
import {
  Container,
} from 'semantic-ui-react'
import Menubar from './components/Menubar'
import {
  PopadsAntiAdblockAd, PopadsStandardAd,
  MonetagMeta, MonetagScriptAd, MonetagDirectAd,
  AdsterraDirectAd, AdsterraSocialBarAd, AdsterraNativeBannerAd,
  AdSenseMeta, AdSenseDisplayAd, AdSenseMultiplexAd, AdSenseInArticleAd
} from './components/Ads'

const Ads = () => {
  return (
    <Container>
      <Menubar />

      {/* Ads
        */}
      <PopadsAntiAdblockAd />
      <PopadsStandardAd />

      <MonetagMeta />
      <MonetagScriptAd />
      <MonetagDirectAd />

      <AdsterraDirectAd />
      <AdsterraSocialBarAd />
      <AdsterraNativeBannerAd />

      <AdSenseMeta />
      <AdSenseDisplayAd />
      <AdSenseMultiplexAd />
      <AdSenseInArticleAd />

    </Container>
  )
}

export default Ads
