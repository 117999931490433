const bool = (val) => ['true', '1', true, 1].includes(val)
// const json = (val) => val && JSON.parse(val)
// const num = (val) => val ? Number(val) : (val === 0 ? 0 : undefined)
// const arr = (str) => str ? str.split(',') : []

const conf = {
  contact: {
    email: 'admin@vuics.com'
  },

  api: {
    url: process.env.REACT_APP_API_URL || 'http://localhost:6369/v1',
  },

  interestForm: {
    url: process.env.REACT_APP_INTEREST_FORM_URL || 'https://forms.gle/GJoUJhyPT93gtLaz7',
  },

  account: {
    start: process.env.REACT_APP_ACCOUNT_START || '/code',
  },

  chat: {
    enable: bool(process.env.REACT_APP_CHAT_ENABLE || true),
    limit: 5,
  },

  code: {
    enable: bool(process.env.REACT_APP_CODE_ENABLE || true),
    console: bool(process.env.REACT_APP_CONSOLE_ENABLE || false),
  },

  library: {
    enable: bool(process.env.REACT_APP_LIBRARY_ENABLE || true),
  },

  run: {
    enable: bool(process.env.REACT_APP_RUN_ENABLE || true),
  },

  profile: {
    enable: bool(process.env.REACT_APP_PROFILE_ENABLE || true),
  },

  keys: {
    enable: bool(process.env.REACT_APP_KEYS_ENABLE || true),
  },

  subscription: {
    enable: bool(process.env.REACT_APP_SUBSCRIPTION_ENABLE || true),
  },

  ads: {
    enable: bool(process.env.REACT_APP_ADS_ENABLE || true),
  },
}

export default conf
